import { Routes, Route } from 'react-router-dom';
import NewRedirect from './pages/NewRedirect';
import Home from './pages/Home';

const Main = () => {
  return (
    <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/add' element={<NewRedirect/>}></Route>
    </Routes>
  );
}

export default Main;