import '../styles/NewRedirect.css';
import { useState } from 'react';
import RuleService from '../services/ruleService';

function NewRedirect() {
  const [errors, setErrors] = useState<string[]>([]);
  
  const submitRule = async (event: any) => {
    event.preventDefault();
    const targetUrl = event.target.targetUrl.value;
    const sourceUrl = event.target.sourceUrl.value;
    const status = event.target.status.value;
    let errorArray = [];

    if(targetUrl === "" || sourceUrl === "" || status === "") {
      errorArray.push('Fields cannot be empty.')
      setErrors(errorArray);
      return;
    }

    if(targetUrl === sourceUrl) {
      errorArray.push('Source and Target URLs cannot be the same.')
    }

    if(!isValidUrl(event.target.sourceUrl.value)) {
      errorArray.push('Invalid Source URL format.')
    }

    if(!isValidUrl(event.target.targetUrl.value)) {
      errorArray.push('Invalid Target URL format.')
    }

    if(isNaN(status)) {
      errorArray.push('Status code must be a number.')
    }

    setErrors(errorArray);
    if(errorArray.length > 0) return;


    let response;

    try {
      
      const response = await RuleService.createRule(
        event.target.sourceUrl.value, 
        event.target.targetUrl.value, 
        +event.target.status.value);

      if(response.success === true) {
        event.target.reset();
        alert("Success");
      }
      else {
        console.log(`[LOGGING FROM /add]: Response Success on POST ${response.success}`);
        alert(`The request returned Success ${response.success}, please check and try again.`)
      }
    }
    catch (error:any) {
      console.log(`[LOGGING FROM /add]: Request error on POST ${response}, ${error.name}`);
      alert("Something went wrong.")
    };
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      console.log(_);
      return false;  
    }
  };

  return (
    <div>
      <form onSubmit={submitRule}>
        <div className='url-wrapper'>
          <div className='input-wrapper'>
            <span className='header'>Source URL</span>
            <input type='text' name='sourceUrl' className='input'/>
          </div>
          <div className='input-wrapper'>
            <span className='header'>Target URL</span>
            <input type='text' name='targetUrl' className='input'/>
          </div>
        </div>
        <div className='status-submit-wrapper'>
          <div className='input-wrapper status-wrapper'>
            <span className='header'>Status</span>
            <select name="status" id="statusSelectList">
              <option selected>Select Status Code</option>
              <option value="301">301 - Moved Permanently</option>
              <option value="302">302 - Found</option>
              <option value="303">303 - See Other</option>
              <option value="307">307 - Temporary Redirect</option>
              <option value="308">308 - Permanent Redirect</option>
            </select>
          </div>
          <div className='input-wrapper submit-wrapper'>
            <input type='submit' className='input submit-button' />
          </div>
        </div>
      </form>
      {errors.map((item) =>
        <p className='errors'>{item}</p>
      )}
    </div>
  );
}

export default NewRedirect;
