import { CreateRedirectResponse, GetRuleListItemsRespose } from "../types";

const RuleService = {
    async getRules() {
        const response = await fetch('/api/getRules');
        const data = await response.json() as GetRuleListItemsRespose;
        return data;
    },

    async deleteRule(id:string) {
        const body = { 
            items: [{id}]};

        const response = await fetch(`/api/deleteRule`, {
            method: 'DELETE',
            headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(body),
            }
        );
        return response;
    },

    async createRule(sourceUrl:string, targetUrl: string, statusCode:number) {
        const body = [{
            redirect: {
                source_url: sourceUrl,
                target_url: targetUrl,
                status_code: statusCode 
            }
          }];

        const response = await fetch(`/api/createRule`, {
            method: 'POST',
            headers: {
				'Content-Type': 'application/json;charset=utf-8',
			},
			body: JSON.stringify(body),
            }
        );

        const data = await response.json() as CreateRedirectResponse;

        return data;
    }
}

export default RuleService;