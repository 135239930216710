import '../styles/Home.css';
import { useEffect, useState } from 'react';
import { Result } from '../types';
import Button from 'react-bootstrap/Button'
import Moment from 'moment';
import RuleService from '../services/ruleService';

function Home() {
  const [rules, setRules] = useState<Result[]>([]);
  
  useEffect(() => {
    async function getRulesList() {
      var rules = await getRules();
      setRules(rules);
    }

    getRulesList()
    // eslint-disable-next-line
  },[]);

  const getRules = async () => {
    try {
      const rules = await RuleService.getRules();

      return rules?.result ?? [];
    }
    catch (error:any) {
      console.log(`[LOGGING FROM /]: Request error on GET ${error.name}`);
      alert("Something went wrong.");
      return [];
    }
  }

  const confirmDelete = async(id:string, source_url:string) => {
    let answer = window.confirm('Are you sure you want to delete the rule for ' + source_url + ' ?');
    if (answer) {
      deleteRule(id);
      console.log('Thing was saved to the database.');
    }
  }

  const deleteRule = async (id:string) => {
    let response;
    try {
      response = await RuleService.deleteRule(id);

      if(response.status === 200)
      {
        var rules = await getRules();
        setRules(rules);
      }
      else {
        console.log(`[LOGGING FROM /]: Response status on DELETE ${response.status}`);
        alert(`The request returned status code ${response.status}, please check and try again.`)
      }
    }
    catch (error:any) {
      console.log(`[LOGGING FROM /]: Request error on DELETE ${response}, ${error.name}`);
      alert("Something went wrong.");
    }
  }

  return (
    <div>
      <table className='rules-table'>
        <tbody>
          <tr>
            <th>Source</th>
            <th>Target</th>
            <th>Status</th>
            <th>Created Date</th>
            <th></th>
          </tr>
          {rules.map((rule) => (
            <tr className='table-row' key={rule.id}>
              <td>{rule.redirect.source_url}</td>
              <td>{rule.redirect.target_url}</td>
              <td>{rule.redirect.status_code}</td>
              <td>{Moment(rule.created_on).format('DD/MM/YYYY')}</td>
              <td><Button variant="danger" onClick={() => confirmDelete(rule.id, rule.redirect.source_url)}>X</Button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Home;
